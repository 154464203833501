import React from "react"
import { StaticQuery, graphql } from "gatsby"
// import Image from "gatsby-image"

function PostFoot() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        // const { author, social } = data.site.siteMetadata
        return (
          <footer
            style={{
              margin: "3rem",
            }}
          >
            <img
              // fixed={data.avatar.childImageSharp.fixed}
              src="https://cdn.kukecloud.com/assets/www/assets/img/kkgzh.png"
              alt="酷课-约课/拓客/智慧经营"
              style={{
                margin: "0 auto",
              }}
            />
          </footer>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    site {
      siteMetadata {
        author
      }
    }
  }
`

export default PostFoot
