import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "./layout"
import PostFoot from "./post_foot"
import RelatedPosts from "./related_posts"
// import Image from "../components/image"
import Tag from "./tag"
import Svg from "./svg"
import SEO from "./seo"
import { PostSvgs } from "./svgs"
import { catMap } from "../utils/maps"
import st from "./css/post_layout.module.scss"

const PostTemplate = function ({ data: { markdownRemark: post } }) {
  // console.log(post)
  // console.log(rest)
  // console.log(all)
  // console.log(permalink)
  const { title, date, permalink, category, tags, excerpt } = post.frontmatter
  return (
    <Layout cur={permalink} showSBar bg="#f4f4f4" stickyHeader>
      <SEO
        title={`${title} - ${catMap[category]} - 酷课文档`}
        keywords={[
          ...tags,
          catMap[category],
          "酷课教程",
          "约课系统使用指南",
          "培训机构管理",
          "会员管理教程"
        ]}
        description={excerpt || `酷课约课系统${catMap[category]}文档：${title}。为培训机构提供专业的使用指南和最佳实践。`}
      />
      <PostSvgs />

      <div className={st.wraper}>
        <div className="clearfix">
          <article className={st.main}>
            <header>
              <h1>{title}</h1>
              {!!date && (
                <p>
                  发表于 <time dateTime={date}>{date}</time>
                </p>
              )}
            </header>

            {excerpt && <section className={st.excerpt}>{excerpt}</section>}

            <section
              className={st.content}
              dangerouslySetInnerHTML={{ __html: post.html }}
            ></section>

            {/* <section className={st.context}>
              <div className="clearfix">
                {previous && (
                  <Link className={st.prev} to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
                {next && (
                  <Link className={st.next} to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                </Link>
                )}
              </div>
            </section> */}

            {category !== "contract" && (
              <section className={st.foot}>
                <PostFoot />
              </section>
            )}
          </article>

          <aside className={st.asideWrap}>
            <section className={st.back}>
              <span
                className="btn-o btn-mini"
                onClick={() => window.history.back()}
              >
                ← 返回
              </span>
            </section>
            <section className={st.cat}>
              <span className="f-mr-05">
                <Svg svgClass={st.catSvg} id="cat" />
              </span>
              <Link to={`/list/${category}/`}>{catMap[category]}</Link>
            </section>
            {tags.length > 0 && (
              <section>
                {tags.map(v => (
                  <Tag key={v} tag={v} />
                ))}
              </section>
            )}
            {tags.length > 0 && (
              <section className="f-mt-2">
                <RelatedPosts tags={tags} slug={post.fields.slug} />
              </section>
            )}
          </aside>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        category
        tags
        permalink
        excerpt
      }
    }
  }
`

export default PostTemplate
